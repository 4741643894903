import { styled } from '@mui/material';

export const Carousel = styled('div')(({ theme }) => ({
  marginTop: theme.spacings.unit(12),
}));

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
}));

export const CarouselWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacings.unit(9),
  marginRight: theme.spacings.unit(9),

  '&&& .alice-carousel__stage-item': {
    width: '100% !important',
  },
}));
