import React, { useEffect, useState, useRef } from 'react';
import BaseCarousel from '../base-carousel/BaseCarousel';
import { Carousel, CarouselWrapper, Container } from './CarouselGrid.style';
import Card from '../../cards/card/Card';
import { Responsive } from 'react-alice-carousel';
import { useWindowSize } from 'usehooks-ts';
import Repeater from '../../repeater/Repeater';
import PaginationArrowsMobile from '../../pagination-arrows-mobile/PaginationArrowsMobile';
import CardGrid from '../../cards/card-grid/CardGrid';

interface Card {
  title: string;
  link: string;
  image: any;
  overlayColor: string;
}

interface GridCarouselProps {
  cards: Card[];
  itemPerPage?: Responsive;
  isPrerenderRequest: boolean;
}

const GridCarousel: React.FC<GridCarouselProps> = (
  props: GridCarouselProps
) => {
  const [columns] = useState<number>(4);
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef();

  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);

  const { width } = useWindowSize();

  useEffect(() => {
    setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const cardsMapper = (cards: any[]) =>
    cards?.map((card, i) => ({
      key: i,
      image: card?.image,
      category: card?.category,
      title: card?.title,
      description: card?.description,
      button: {
        ...(card?.button || {}),
        link: card?.button?.url,
      },
      overlayColor: card?.overlayColor,
      link: card?.link,
    }));

  const repeaterRender = (items: any[]) => {
    return items?.reduce((acc, curr, i) => {
      if (curr) {
        if (!acc || !acc[Math.floor(i / columns)]) {
          acc = {
            ...acc,
            [Math.floor(i / columns)]: [curr],
          };
        } else {
          acc[`${Math.floor(i / columns)}`].push(curr);
        }
      }
      return acc;
    }, {} as any);
  };

  const carouselItems = React.Children.toArray(
    Object.values(repeaterRender(cardsMapper(props?.cards)))?.map((items) => {
      return (
        <Repeater
          spacing={1}
          cols={{ xs: 6, sm: 6 }}
          data={items}
          props={{ isPrerenderRequest: props.isPrerenderRequest }}
          component={CardGrid}
        />
      );
    })
  );

  return (
    <Carousel>
      <Container>
        <PaginationArrowsMobile
          index={activeIndex}
          totalItems={carouselItems.length}
          onLeftArrowClick={slidePrev}
          onRightArrowClick={slideNext}
        >
          <CarouselWrapper>
            <BaseCarousel
              {...props}
              responsive={props.itemPerPage}
              mouseTracking={true}
              disableButtonsControls
              disableDotsControls
              activeIndex={activeIndex}
              onSlideChanged={syncActiveIndex}
              items={carouselItems}
              dotsAlignment={'center'}
              refFromParentCb={(refFromChild: any) => {
                ref.current = refFromChild;
              }}
            />
          </CarouselWrapper>
        </PaginationArrowsMobile>
      </Container>
    </Carousel>
  );
};

export default GridCarousel;
