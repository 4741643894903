import React, { useEffect, useState } from 'react';
import CardGrid from '../../../components/cards/card-grid/CardGrid';
import Repeater from '../../../components//repeater/Repeater';
import { MainRepeater, StyledContentWrapper } from './Grid.style';
import {
  HideOnDesktop,
  HideOnMobile,
} from 'packages/corporate-ui/src/styles/shared';
import GridCarousel from '../../../components/carousel/carousel-grid/CarouselGrid';
import SectionHeader from '../../../components/section-header/SectionHeader';
import { useMediaQuery, useTheme } from '@mui/material';

interface GridProps {
  mainTitle: string;
  title: string;
  mainTitleFontSize?: number;
  titleFontSize?: number;
  mainTitleMobileFontSize?: number;
  titleMobileFontSize?: number;
  titleMobileFontWeight?: number;
  titleFontWeight?: number;
  mainTitleMobileFontWeight?: number;
  mainTitleFontWeight?: number;
  cards: any;
}

const Grid: React.FC<any> = ({
  data,
  isPrerenderRequest,
}: {
  data: GridProps;
  isPrerenderRequest: boolean;
}) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('sm'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, [isDesktop]);

  return (
    <StyledContentWrapper>
      <SectionHeader
        subtitle={data?.mainTitle ?? ''}
        title={data?.title ?? ''}
        titleFontSize={data?.titleFontSize}
        mainTitleFontSize={data?.mainTitleFontSize}
        titleMobileFontSize={data?.titleMobileFontSize}
        mainTitleMobileFontSize={data?.mainTitleMobileFontSize}
        mainTitleFontWeight={data?.mainTitleFontWeight}
        mainTitleMobileFontWeight={data?.mainTitleMobileFontWeight}
        titleFontWeight={data?.titleFontWeight}
        titleMobileFontWeight={data?.titleMobileFontWeight}
      />
      <HideOnMobile>
        <MainRepeater>
          <Repeater
            spacing={1}
            cols={{ xs: 6, sm: 6, md: 6, lg: 3, xl: 3 }}
            data={data.cards}
            component={CardGrid}
            props={{ isPrerenderRequest: isPrerenderRequest }}
          />
        </MainRepeater>
      </HideOnMobile>
      <HideOnDesktop>
        <GridCarousel
          cards={data.cards}
          isPrerenderRequest={isPrerenderRequest}
        />
      </HideOnDesktop>
    </StyledContentWrapper>
  );
};

export default Grid;
