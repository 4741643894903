import { styled } from '@mui/material';
import { getCompany } from '../../../utils/company';
import { Companies } from '../../../models/domain/enum';

const company = getCompany();

export const CardStyled = styled('div', {
  shouldForwardProp: (prop) =>
    !['overlay', 'overlayHover'].includes(prop as string),
})(({ theme, overlay, overlayHover }) => ({
  background:
    company === Companies.FELSINEA
      ? 'radial-gradient(50.35% 49.42% at 50.00% 50%, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.15) 100%)'
      : 'transparent',
  position: 'relative',
  width: '100%',
  aspectRatio: '1/1',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.white,
  cursor: 'pointer',
  '&:after': {
    content: '""',
    opacity: 1,
    boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
      overlay ? overlay + '80' : 'transparent'
    }`,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: -1,
  },
  '&:hover': {
    '&:after': {
      boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
        overlayHover ? overlayHover + '80' : theme.colors.overlayColor + '80'
      }`,
    },
  },
}));

export const Title = styled('a')(({ theme }) => ({
  position: 'absolute',
  color: theme.colors.white,
  bottom: company === Companies.FELSINEA ? theme.spacings.unit(6) : 'auto',
  fontSize:
    company === Companies.FELSINEA
      ? theme.fonts.utility.size(12)
      : theme.fonts.utility.size(5),
  fontWeight: company === Companies.FELSINEA ? '700' : '500',
  textAlign: 'center',
  textDecoration: 'none',
  wordBreak: 'normal',
  overflowWrap: ['break-word', 'anywhere'],
  padding: theme.spacings.unit(2),
  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: theme.fonts.utility.size(16),
    fontWeight: '200',
  },
  [theme.breakpointsMediaQuery.only('s')]: {
    fontSize: theme.fonts.utility.size(12),
    fontWeight: '350',
  },
  [theme.breakpointsMediaQuery.only('ssm')]: {
    fontSize: theme.fonts.utility.size(7.5),
    fontWeight: '400',
  },
  ...(company === Companies.FELSINEA && {
    [theme.breakpointsMediaQuery.down('sm')]: {
      fontSize: theme.fonts.utility.size(10),
      fontWeight: '350',
    },
    [theme.breakpointsMediaQuery.up('md')]: {
      fontSize: theme.fonts.utility.size(12),
      fontWeight: '700',
    },
  }),
}));

export const CardContainerStyled = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const CardImageContainerStyled = styled('div', {
  shouldForwardProp: (prop) =>
    !['isLast', 'background', 'withOverlay'].includes(prop as string),
})(() => ({
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 'auto',
  zIndex: '-3',
  aspectRatio: '1/1',
}));

export const CardContentContainerStyled = styled('div', {
  shouldForwardProp: (prop) =>
    !['isLast', 'background', 'withOverlay'].includes(prop as string),
})(() => ({}));

export const CardLayerStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(270deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0) 50%,  rgba(255, 255, 255, 0.40) 100%)',
  transition: 'opacity 2s',
}));
