import { styled } from '@mui/material';
import { MobilePaddingLessContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(MobilePaddingLessContentWrapper)(
  ({ theme }) => ({
    marginBottom: theme.spacings.unit(24),
    [theme.breakpointsMediaQuery.up('md')]: {
      padding: `0 ${theme.spacings.unit(48)}`,
    },
  })
);

export const MainRepeater = styled('div')(({ theme }) => ({
  marginTop: theme.spacings.unit(12),
  display: 'flex',
  flexDirection: 'row',
  flexAlign: 'center',
  justifyContent: 'center',
}));
